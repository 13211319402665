var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"search-page"}},[_c('script',{attrs:{"type":"application/ld+json"}},[_vm._v("\n    "+_vm._s(_vm.schemaList)+"\n  ")]),_c('div',{staticClass:"grid-row",attrs:{"id":"search-page-content"}},[_c('div',{staticClass:"col-3 col-l-4 col-s-5 align"},[(_vm.query && _vm.screenWidth > 799)?_c('search-filters',{staticClass:"search-filters",attrs:{"query":_vm.query}}):_vm._e()],1),_c('div',{staticClass:"col-10 col-l-9 col-s-11"},[_c('div',{staticClass:"search-results"},[(_vm.maxPageJump)?_c('notification',{staticClass:"search-action-notify"},[_vm._v("\n          We are unable to support jumping forward more than\n          "+_vm._s(_vm.maxPageJumpLimit)+" pages at a time. You have been brought to the\n          closest allowed results page.\n        ")]):_vm._e(),(_vm.notify)?_c('notification',{staticClass:"search-action-notify",attrs:{"notification-style":_vm.notify.type}},[_vm._v("\n          "+_vm._s(_vm.notify.message)+"\n        ")]):_vm._e(),(_vm.previousQuery)?_c('notification',{staticClass:"search-replacement"},[_vm._v("\n          Showing results for "),_c('span',[_vm._v(_vm._s(_vm.query))]),_vm._v(" (your query\n          "),_c('span',[_vm._v(_vm._s(_vm.previousQuery))]),_vm._v(" returned 0 results)\n        ")]):_vm._e(),(_vm.query)?_c('suggested-authors',{staticClass:"search-info-box",attrs:{"query":_vm.queryWithoutConstraints}}):_vm._e(),(_vm.query)?_c('author-info',{attrs:{"query":_vm.queryWithoutConstraints}}):_vm._e(),(_vm.hitCount)?_c('div',{attrs:{"id":"search-results--results--details--top"}},[_vm._v("\n          "+_vm._s(((_vm.currentPage - 1) * _vm.pageLimit + 1).toLocaleString())+"-"+_vm._s(_vm.currentPage * _vm.pageLimit > _vm.hitCount
              ? _vm.hitCount.toLocaleString()
              : (_vm.currentPage * _vm.pageLimit).toLocaleString())+"\n          of\n          "),_c('span',{staticClass:"semi-bold"},[_vm._v(_vm._s(_vm.hitCount.toLocaleString()))]),_vm._v("\n          results\n        ")]):_vm._e(),(_vm.query && !_vm.loading)?_c('suggested-search',{staticClass:"suggested-search",attrs:{"query":_vm.query,"hit-count":_vm.hitCount}}):_vm._e(),(_vm.hitCount)?_c('hr',{staticClass:"separator thick"}):_vm._e(),(!_vm.loading && !_vm.hitCount)?_c('notification',[_vm._v("\n          There are no citations matching your query.\n        ")]):[_c('div',{staticClass:"search-results-bar"},[_c('div',{attrs:{"id":"search-results-sorter"}},[_c('label',{attrs:{"for":"search-results--sort--by"}},[_vm._v("Sort by: ")]),[_c('div',{attrs:{"id":"search-results--sorting--options"}},_vm._l((_vm.filterOptions),function(item){return _c('div',{key:item.value,staticStyle:{"display":"flex","align-items":"baseline"},on:{"click":function($event){return _vm.onSorterSelected(item)}}},[_c('input',{attrs:{"type":"radio","name":'option'},domProps:{"value":item.value,"checked":item.selected},on:{"change":function($event){return _vm.onSorterSelected(item)}}}),_c('label',{attrs:{"for":item.value}},[_vm._v(_vm._s(item.text)+"\n                      "),_c('sup',[(item.value === 'Relevance')?_c('tooltip',[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_c('b',[_vm._v("Relevance:")]),_vm._v(" "+_vm._s(item.tooltipText)+"\n                        ")]):_vm._e()],1)])])}),0)],(_vm.currentSortOption.text === 'Date')?_c('action',{attrs:{"id":"date-published-arrow"},on:{"click":function($event){$event.preventDefault();return _vm.updateDatePublishedSorter.apply(null, arguments)}}},[_c('i',{class:[
                    'fas',
                    _vm.currentSortOption.value === 'FIRST_PDATE_D+desc'
                      ? 'fa-arrow-up'
                      : 'fa-arrow-down',
                  ],attrs:{"slot":"icon"},slot:"icon"})]):_vm._e()],2),(_vm.hitCount > _vm.pageLimit)?_c('pagination',{attrs:{"id":"search-results--pagination","total-size":_vm.hitCount,"page-size":_vm.pageLimit,"max-number-of-displayed-pages":3,"current-page":_vm.currentPage},on:{"onPageEntered":_vm.onPageEntered}}):_vm._e()],1),(!_vm.loading)?_c('list',{attrs:{"list":_vm.citations,"separator-below":true},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return _c('citation',{attrs:{"id":'search-results--single--block-' + item.id,"citation":item}},[_c('div',{attrs:{"slot":"middle-slot"},domProps:{"innerHTML":_vm._s(item.snippets)},slot:"middle-slot"}),_c('template',{slot:"label-slot"},[(
                    _vm.exportList.find(
                      (art) =>
                        art.source === item.source && art.id === item.id
                    )
                  )?_c('action',{on:{"click":function($event){return _vm.removeSelectedArticle(item)}}},[_c('i',{staticClass:"fas fa-check",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Added to export list\n                ")]):(_vm.exportList.length === _vm.EXPORT_LIST_LIMIT)?_c('tooltip',[_c('action',{attrs:{"slot":"trigger","disabled":true},slot:"trigger"},[_c('i',{staticClass:"fas fa-plus",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Add to export list\n                  ")]),_vm._v("\n                  The export list is full!\n                ")],1):_c('action',{attrs:{"id":'search-results--addTo--exportList-' + item.id},on:{"click":function($event){return _vm.addSelectedArticle(item)}}},[_c('i',{staticClass:"fas fa-plus",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Add to export list\n                ")])],1)],2)}}],null,false,2239956920)}):_c('loading'),(!_vm.loading)?_c('div',{staticClass:"search-results-bar"},[_c('div',{attrs:{"id":"search-results--results--details--bottom"}},[_vm._v("\n              "+_vm._s(((_vm.currentPage - 1) * _vm.pageLimit + 1).toLocaleString())+"-"+_vm._s(_vm.currentPage * _vm.pageLimit > _vm.hitCount
                  ? _vm.hitCount.toLocaleString()
                  : (_vm.currentPage * _vm.pageLimit).toLocaleString())+"\n              of\n              "),_c('span',{staticClass:"semi-bold"},[_vm._v(_vm._s(_vm.hitCount.toLocaleString()))]),_vm._v("\n              results\n            ")]),(_vm.hitCount > _vm.pageLimit)?_c('pagination',{attrs:{"total-size":_vm.hitCount,"page-size":_vm.pageLimit,"max-number-of-displayed-pages":3,"current-page":_vm.currentPage},on:{"onPageEntered":_vm.onPageEntered}}):_vm._e()],1):_vm._e()]],2)]),_c('div',{staticClass:"col-3 align"},[(_vm.query && _vm.screenWidth > 1000)?_c('action-bar',{on:{"notify":(n) => (_vm.notify = n)}}):_vm._e()],1)]),_c('sticky-footer',{attrs:{"screen-width":_vm.screenWidth},on:{"notify":(n) => (_vm.notify = n)}},[(_vm.query)?_c('search-filters',{ref:"Filter",attrs:{"slot":"menu1","query":_vm.query,"mobile":true},slot:"menu1"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }